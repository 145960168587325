import React from 'react';
import styled from 'styled-components';
import {boldBrandFont} from '@partssourceinc/react-ui-core';
import {defaultPhoneNumber} from 'data/DefaultPhoneNumber';

const Wrapper = styled.div`
    max-width: 495px;
    padding: 48px 0 96px 0;
    margin: 0 auto;
    text-align: center;
`;

const IconHolder = styled.div`
    margin-bottom: 16px;    

    img {
        vertical-align: middle;
    }
`;

const Title = styled.h1`
    margin-bottom: 16px;
`;

const ResponseText = styled.p`
    font-size: 16px;
    margin-bottom: 20px;

    a {
        color: #4a4a4a;
        text-decoration: underline;

        &.phone-url {
            color: #007bff;
        }
    }

    a:hover {
        color: #777;
    }
`;

const DownloadGuide = styled.div`   
    text-align: center;
    ${boldBrandFont('16px')}
    
    a {
        color: #005ba6;
        text-decoration: none;
    }
`;

const HubSpotSubmitRequest = ({displayDownloadLink}) => {
    return (
        <Wrapper>
            <IconHolder>
                <img src={`${displayDownloadLink ? '/images/pdf_download.png' : '/images/check_mark.png'}`} />
            </IconHolder>
            <Title>Your request has been submitted</Title>
            <ResponseText>
                Your account request has been sent to PartsSource for Suppliers. If you have questions, 
                please contact <a href="mailto:supplier_support@partssource.com" rel=" noopener">supplier_support@partssource.com</a> or 
                call <a className="phone-url" href={'tel:+1' + defaultPhoneNumber}>{defaultPhoneNumber}</a> and ask for the Supplier Pro representatives.
            </ResponseText>
            {displayDownloadLink && <DownloadGuide>
                <a data-ea-exit-link="Media Guide" href="https://prod-hippo-api.partsfinder.com/binaries/content/assets/downloads/partssource-media-guide.pdf">
                    Download Media Guide
                </a>
            </DownloadGuide>}
        </Wrapper>
    );
} 

export default HubSpotSubmitRequest;
